<template>
  <VitePwaManifest />
  <NuxtLoadingIndicator color="#0D394D" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script setup lang="ts">
useUserCentrics().setup()
provideHeadlessUseId(() => useId())
</script>
