import { default as indexk878UI3mg5Meta } from "/workspace/pages/appointment/cancel/index.vue?macro=true";
import { default as _91step_93sBF2P75VLrMeta } from "/workspace/pages/appointment/new/[step].vue?macro=true";
import { default as _91uuid_93HBMQy8k8TNMeta } from "/workspace/pages/appointment/success/[uuid].vue?macro=true";
import { default as welcomekYRKmHhlODMeta } from "/workspace/pages/appointment/welcome.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
export default [
  {
    name: "appointment-cancel",
    path: "/appointment/cancel",
    component: () => import("/workspace/pages/appointment/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "appointment-new-step",
    path: "/appointment/new/:step()",
    meta: _91step_93sBF2P75VLrMeta || {},
    component: () => import("/workspace/pages/appointment/new/[step].vue").then(m => m.default || m)
  },
  {
    name: "appointment-success-uuid",
    path: "/appointment/success/:uuid()",
    meta: _91uuid_93HBMQy8k8TNMeta || {},
    component: () => import("/workspace/pages/appointment/success/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "appointment-welcome",
    path: "/appointment/welcome",
    meta: welcomekYRKmHhlODMeta || {},
    component: () => import("/workspace/pages/appointment/welcome.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  }
]