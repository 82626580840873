export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Mobile web app to book appointments for meevo."},{"name":"mobile-web-app-capable","content":"yes"},{"name":"theme-color","content":"#ffffff"},{"name":"apple-mobile-web-app-status-bar-style","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","href":"/img/pwa/meevo-apple-icon.png"},{"rel":"icon","sizes":"32x32","href":"/favicon.ico"},{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"},{"rel":"preconnect","href":"https://privacy-proxy.usercentrics.eu"},{"rel":"preload","href":"https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js","as":"script"}],"style":[],"script":[{"src":"https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js","async":true}],"noscript":[],"title":"meevo Terminbuchung","charset":"utf-8","viewport":"width=device-width, initial-scale=1","htmlAttrs":{"class":"h-full","lang":"de"},"bodyAttrs":{"class":"h-full"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000